var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('ozi-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"persistent":"providerListTable"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.handleCreate.apply(null, arguments)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$tc('label.create'))+" ")],1)],1)]},proxy:true},{key:"item.categories.name_short",fn:function(ref){
var item = ref.item;
return _vm._l((item.categories),function(category){return _c('v-chip',{key:category.key,staticClass:"mx-1",attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(_vm.$langobj.parse(category.name_short))+" ")])})}},{key:"item.health_teams.name_short",fn:function(ref){
var item = ref.item;
return _vm._l((item.health_teams),function(health_team){return _c('v-chip',{key:health_team.id,staticClass:"mx-1",attrs:{"small":"","label":"","outlined":"","color":"#216bbf"}},[_vm._v(" "+_vm._s(_vm.$langobj.parse(health_team.name_short))+" ")])})}},{key:"item.verification",fn:function(ref){
var item = ref.item;
return [(item.verification && item.verification.status === 'verified')?[_c('v-icon',{attrs:{"color":"#50a988"}},[_vm._v("mdi-check-decagram")]),_c('v-avatar',{attrs:{"color":_vm.avatarColor(_vm.userInitials(item.verification)),"size":"20"}},[_c('span',{staticClass:"white--text",staticStyle:{"font-size":"0.8em","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.userInitials(item.verification))+" ")])]),_vm._v(" "+_vm._s(item.verification.timestamp.substring(0, 10))+" ")]:_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleEdit(item)}}},[_vm._v("mdi-pencil")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }