































































import Vue from 'vue';
import ProviderUtils, { Provider } from '@/utils/ProviderUtils';
import { DataTableHeader } from 'vuetify';
import { Verification } from '@/utils/VerificationUtils';

const avatarColors = [
  '#216bbf',
  '#9ECBFF',
  '#C785FF',
  '#2c92ff',
  '#9066B3',
  '#50a988',
  '#faab26',
  '#9a2b64',
  '#a7a9ac',
  '#e23f93',
];

export default Vue.extend({
  computed: {
    headers(): DataTableHeader<Provider>[] {
      return [
        {
          text: this.$i18n.tc('provider.name_long'),
          value: 'name_long',
        },
        {
          text: this.$i18n.tc('provider.category.label', 2),
          value: 'categories.name_short',
        },
        {
          text: this.$i18n.tc('provider.health_team', 2),
          value: 'health_teams.name_short',
        },
        {
          text: this.$i18n.tc('languageDesignation.label_short'),
          value: 'language_designation.key',
        },
        {
          text: this.$i18n.tc('provider.number_of_pos'),
          value: 'number_of_pos',
        },
        {
          text: this.$i18n.tc('verification.label', 2),
          value: 'verification',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },

  data: (): {
    items?: Provider[];
  } => ({
    items: undefined,
  }),

  async mounted() {
    try {
      this.items = await ProviderUtils.api.listConcise();
    } catch (error) {
      console.error(error);
    } finally {
      //
    }
  },

  methods: {
    async handleEdit(item: Provider) {
      await this.$router.push({
        name: 'provider-edit',
        params: { id: item.id.toString() },
      });
    },

    async handleCreate() {
      await this.$router.push({
        name: 'provider-create',
      });
    },

    userInitials(item: Verification) {
      return (
        (item.user_first_name ? item.user_first_name.charAt(0) : '') +
        (item.user_last_name ? item.user_last_name.charAt(0) : '')
      );
    },

    avatarColor(initials: string) {
      const charCodes = initials
        .split('')
        .map((char) => char.charCodeAt(0))
        .join('');
      const value = parseInt(charCodes, 10);
      return avatarColors[value % avatarColors.length];
    },
  },
});
